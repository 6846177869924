import React from 'react'
import Layout from '../components/layouts/Layout'
import { InfoPagesWrapper } from '../components/layouts'
import { Button } from '../components/elements'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'

const Shredding = () => {
  const data = useStaticQuery(graphql`
    query {
      shredding:file(relativePath: { regex: "/shredding.png/" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Example 5, Shredding Company" />
      <InfoPagesWrapper>
        <h1>Security In Shredding, Ireland</h1>

        <p>
          Security in Shredding is a leader in the paper shredding services
          industry in Ireland. They provide a range of GDPR compliant shredding
          services serving the entire country. Here we went with a more content
          heavy design in order to showcase their wide range of offerings. Built
          in collaboration with Connor Adam-Hein of{' '}
          <a href="https://www.rhproductions.dev/" target="_blank" rel="noreferrer">
            Random House Productions.
          </a>
        </p>
        <a
          href="https://www.securityinshredding.com/"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Take me there!</Button>
        </a>
      </InfoPagesWrapper>
      <div
        css={`
          width: 80%;
          margin: 2rem auto;
          img {
            z-index: -1 !important; // AHHHHH no idea why but this fixed the problem w/ it covering the header
            overflow: hidden !important;
          }
        `}
      >
        <Img fluid={data.shredding.childImageSharp.fluid} />
      </div>
    </Layout>
  )
}

export default Shredding
